// Navigation mobile
function nav_mobile() {
  $('#nav-mobile-trigger').on('click', function() {
    var viewport = $(window).innerWidth();
    if(viewport > 560){
      $('html').addClass('menu-lateral-open');
    }else {
      $('body').toggleClass('menu-open');
      $('#nav-main').toggle();
    }
  });
  $('#menu-lateral .btn-close, .menu-lateral-mask, #menu-lateral .close-mask').on('click',function(){
    $('html').removeClass('menu-lateral-open');
  });
}

$('.filter-btn-mobile').on('click', function(){
  $('.category-filter').show();
  $(this).hide();
});


function f_subscribe(){
  var isChecked = $('#btn-switch').prop('checked');
  if(!isChecked){
    $('.input-hide').hide();
  }else{
    $('.input-hide input').attr('required','required');
  }
  $('#btn-switch').on('change',function(){
    $('.input-hide').toggle();
    $('.input-hide').toggleClass('active');
    if($('.input-hide').hasClass('active')){
      $('.input-hide input').attr('required','required');
    }else{
      $('.input-hide input').removeAttr('required')
    }

  })

  var isChecked = $('#btn-switch-password').prop('checked');
  if(!isChecked){
      $('.input-hide-password').hide();
  }else{
      $('.input-hide-password input').attr('required','required');
  }
  $('#btn-switch-password').on('change',function(){
      $('.input-hide-password').toggle();
      $('.input-hide-password').toggleClass('active');
      if($('.input-hide-password').hasClass('active')){
          $('.input-hide-password input').attr('required','required');
      }else{
          $('.input-hide-password input').removeAttr('required')
      }

  })

  $('.adresse-toggle').hide();
  var isCheckedAdress = $('#btn-switch-adress').prop('checked');
  if(isCheckedAdress){
    $('.adresse-toggle').show();
    $('.adresse-toggle').addClass('active');
    $('.adresse-toggle .form-control-bis').attr('required','required')
  }

  $('#btn-switch-adress').on('change',function(){
    $('.adresse-toggle').toggle();
    $('.adresse-toggle').toggleClass('active');
    if($('.adresse-toggle').hasClass('active')){
      $('.adresse-toggle .form-control-bis').attr('required','required')
    }else{
      $('.adresse-toggle .form-control-bis').removeAttr('required')
    }
  })

}


// Navigation size
function nav_size() {
  var position = $(window).scrollTop();
  if (position > 100) {
    $('body').addClass('header-small');
  } else {
    $('body').removeClass('header-small');
  }
  $(window).scroll(function() {
    if ($(this).scrollTop() > 100) {
      $('body').addClass('header-small');
    } else {
      $('body').removeClass('header-small');
    }
  });
}

// Navigation sub menu
function nav_sub_menu() {
  var viewport = $(window).width();
  if (viewport < 576) {
    $('#nav-main li[data-navsub] > a').on('click', function(event) {
      $(this).next().toggle();
      var navSub = $(this).parent();
      var isFocus = navSub.hasClass('focus');
      $('#nav-main li[data-navsub]').removeClass('focus');
      if (!isFocus) {
        navSub.addClass('focus');
      }
      return false;
    });
  } else {
    $('#nav-main li[data-navsub] > a').on('click', function(event) {
      var navSub = $(this).parent();
      var isFocus = navSub.hasClass('focus');
      $('#nav-main li[data-navsub]').removeClass('focus');
      if (!isFocus) {
        navSub.addClass('focus');
      }
      event.stopPropagation();
      return false;
    });
    $('html').click(function() {
      $('#nav-main li[data-navsub]').removeClass('focus');
    });
  }
}

// Scroll top button
function scroll_top() {
  $('#btn-back-top').fadeOut(0);
  $(window).scroll(function() {
    if ($(this).scrollTop() > 220) {
      $('#btn-back-top').fadeIn(300);
    } else {
      $('#btn-back-top').fadeOut(300);
    }
  });
  $('#btn-back-top').on('click', function() {
    $('html, body').animate({
      scrollTop: 0
    }, '500');
    return false;
  });
}


function f_inputEffect() {
  $('.form-control-bis').each(function(i) {
    isEmpty = $(this).val();
    if (!isEmpty == '') {
      $(this).addClass('active');
    }

    $(this).on('blur', function() {
      if ($(this).val() === '') {
        $(this).removeClass('active');
      } else {
        $(this).addClass('active');

      }
    });
  });
}


function f_product_toggle(){
  $('.toggle-wp .toggle-content').hide();
  $('.toggle-wp .title').on('click',function(){
    $(this).parent().toggleClass('open');
    $(this).next().toggle();
  });
}

// Initialisation
f_inputEffect();
nav_mobile();
nav_sub_menu();
nav_size();
scroll_top();
f_subscribe();
f_product_toggle();
