// Radiopicker switch
$('.btn-switch-wp .label').on('click', function() {
  $(this).prev().find('.checkbox-toggle').trigger('click');
});

$('.submitOnChange').on('change', function(){
    $(this).parents('form').submit();
});


if ($("#mode-livraison").length) {
    $("#mode-livraison input").change(function() {
        $.ajax({
            method: "PUT",
            url: $("#mode-livraison").data('url'),
            data: {
                'livraison': $(this).val(),
                '_token': $("#mode-livraison").data('csrftoken')
            },
            success: function(data) {
                $('.total-resa-lateral').html(data);
            }
        });
    });

    $("#mode-livraison input:checked").trigger('change');
}
