// JS dependency
var stickykit = require('sticky-kit/dist/sticky-kit.js');

// Initialisation
function stickykit_init() {
  var viewport = $(window).width();
  if (viewport > 576) {
    var offset;
    if(viewport > 991){
      offset = 65
    }else{
      offset = 15
    }
    $("#sticky-wp").stick_in_parent({
      offset_top: offset
    });
  } else {
    $("#sticky-wp").trigger("sticky_kit:detach");
  }
}

stickykit_init();


$(window).on('resize', function() {
  stickykit_init();
});


$('.category-filter.accordion .title-sub').on('click', function(){
  isLinkDirect = $(this).hasClass('no-child');
  if(!isLinkDirect){
    isOpen = $(this).hasClass('open');
    if(isOpen) {
      $(this).removeClass('open');
    }else {
      $('.category-filter .title-sub').removeClass('open');
      $(this).addClass('open');
    }
    stickykit_init();
    return false;
  }
});
$('.category-filter .title-sub.opened').trigger('click');
