// JS dependency
var slick = require('slick-carousel');

// CSS dependency
import '../../../../node_modules/slick-carousel/slick/slick.css';

// Example
$('#slick-home').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    fade: false,
    arrows: true,
    autoplay: true,
    adaptiveHeight: true,
    cssEase: 'ease-in-out',
    speed: 400,
    autoplaySpeed: 6000,
    appendArrows: '#slick-home-arrows',
    prevArrow: '<button class="arrow-prev-wp"><span class="sprite arrow-prev"></span></button>',
    nextArrow: '<button class="arrow-next-wp"><span class="sprite arrow-next"></span></button>',
    responsive: [{
        breakpoint: 1200,
        settings: {}
    },
        {
            breakpoint: 991,
            settings: {}
        },
        {
            breakpoint: 576,
            settings: {
                arrows: false,
                dots: true
            }
        },
    ]
});

// Example animations
setTimeout(function() {
    $('#slick-home .slick-active').addClass('animation');
}, 250);

$('#slick-home').on('afterChange', function(event, slick, currentSlide) {
    $('#slick-home .slick-active').addClass('animation');
});

$('#slick-home').on('beforeChange', function(event, slick, currentSlide) {
    $('#slick-home .slick-active').removeClass('animation');
});


$('#slick-product').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    fade: false,
    arrows: false,
    autoplay: true,
    adaptiveHeight: false,
    cssEase: 'ease-in-out',
    speed: 400,
    autoplaySpeed: 6000,
    responsive: [{
        breakpoint: 1200,
        settings: {}
    },
        {
            breakpoint: 991,
            settings: {}
        },
        {
            breakpoint: 576,
            settings: {
                autoplay: false
            }
        },
    ]
});

$("#slick-product-nav .button").on('click', function(){
    var number = $(this).attr('data-trigger');
    $('#slick-product').slick('slickGoTo', parseInt(number-1), false);
})
